import { Box, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import routes from '../../api/routes';
import { AssociationFiltersDto } from '../../dto/association';
import { AssociationStats, calculateStats } from '../../dto/associationUtils';
import { MissionDto } from '../../dto/mission';
import { MissionDailyReportDto } from '../../dto/missionReport';
import useFetch from '../../hooks/useFetch';
import { formatNumber } from '../../utils';
import { Card, CardContainer } from '../Cards';

const DashboardDaily = ({
	day,
	filters,
	mission,
	hideDetails = false,
}: {
	day: any;
	filters: AssociationFiltersDto;
	mission: MissionDto;
	hideDetails?: boolean;
}) => {
	const [, dayPartialDashboardStats] = useFetch<AssociationStats>(
		routes.associations.dashboardStats({}, filters),
		[JSON.stringify(filters)],
	);
	let date_str = moment(day).format('YYYY-MM-DD');
	const [, dailyReport] = useFetch<MissionDailyReportDto>(
		routes.missions.reports.dailyReport({ id: mission.id.toString() }, { date: date_str }),
	);

	let dashboardStats = calculateStats((dayPartialDashboardStats || {}) as any);

	return (
		<Box>
			<CardContainer display="flex" mt={1} mx={2}>
				<Card title="Ville">{dailyReport?.location}</Card>
				<Card title="HR">{dashboardStats.streetHours}</Card>
				<Card title="bs réel">
					<Typography style={{ fontSize: 20, margin: -2 }}>
						{formatNumber(dashboardStats.completedDonationsCount, 2)}
					</Typography>
					<Typography style={{ fontSize: 14, margin: -2 }}>
						{formatNumber(
							dashboardStats.completedDonationsCount - dashboardStats.completedDonationsOver25Count,
							2,
						)}
					</Typography>
				</Card>
				<Card title="Tx Réel (global)">{dashboardStats.realRate}</Card>
				<Card title="Tx Réel (+25 ans)">{dashboardStats.realRateOver25}</Card>
				<Card title="don moyen">{formatNumber(dashboardStats.averageDonation, 2)}€</Card>
				<Card title="%18-30">{formatNumber(dashboardStats._18_20_percent, 2)}%</Card>
				<Card title="%Stop PA">{formatNumber(dashboardStats.stopPAPercent, 2)}%</Card>
				{!hideDetails && (
					<>
						<Card title="%HR/HP">{dashboardStats.streetPaidHoursRatio}</Card>
						<Card title="Absences">{dashboardStats.absence}</Card>
					</>
				)}
			</CardContainer>
		</Box>
	);
};

export default DashboardDaily;
